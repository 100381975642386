<!-- CodeSamples.vue -->
<template>
  <div>
   
    <!-- Add v-btn to toggle the form -->
    

     <div> <!-- <v-btn @click="sortwithLevel()">Sort</v-btn> -->
    </div>

    <v-container class="bg-surface-variant" style="max-width: 100%;">
    <v-row style="text-align: center; "
    justify="center"
    >
      <v-col cols="4" style="text-align:left;">
        <v-sheet class="pa-2">
          <v-btn color="success" @click="fetchCodeWithLevel('beginner')">Beginner</v-btn>
          <v-btn color="warning" @click="fetchCodeWithLevel('intermediate')">Intermediate</v-btn>
          <v-btn color="error" @click="fetchCodeWithLevel('advanced')">Advanced</v-btn>
        </v-sheet>
      </v-col>
      <v-col cols="2" style="text-align: end;">
        <v-sheet class="pa-2">
          <v-btn color="primary" @click="showFormAdd = !showFormAdd" append-icon="mdi-plus" > <template v-slot:prepend>
        <v-icon color="success"></v-icon>
      </template>New Code</v-btn>
        </v-sheet>
      </v-col></v-row>
    </v-container>
   
     <v-dialog v-model="showFormAdd" max-width="1200" 
      persistent> <v-btn color="error" @click="showFormAdd = false" append-icon="mdi-close" style="float: right;"></v-btn>
      <AddCodeSample style="background: aliceblue;padding: 8px;" />
     </v-dialog>

     <v-dialog v-model="showFormUpdate" max-width="1200" 
      persistent> <v-btn color="error" @click="showFormUpdate = false" append-icon="mdi-close" style="float: right;"></v-btn>
      <UpdateCodeSample :updateItem="updateId" :updateObject="updateObject" style="background: aliceblue;padding: 8px;" />
     </v-dialog>
    
    
    <!-- List existing code samples -->
    <div v-for="(sample, index) in codeSamples" :key="index" class="code-sample">
      <div style="margin: 8px;">
      <v-card  :title="sample.title" :subtitle=" sample.languageType +' / ' +  sample.experienceLevel" variant="outlined">
        <v-card-text>
           <pre><code>{{ sample.code }}</code></pre>
        </v-card-text>
        <v-card-text>
           {{ sample.detail }}
        </v-card-text>
        <v-card-actions>
           <v-btn class="text-none mb-4"
                  color="indigo-darken-3"
                  size="x-large"
                  variant="outlined"
                  block @click="deleteCodeSample(sample._id)">Delete
            </v-btn>
        </v-card-actions>
        <v-card-actions>
           
            <v-btn class="text-none mb-4"
                  color="indigo-darken-3"
                  size="x-large"
                  variant="outlined"
                  block @click="showFormUpdate = true, updateObject = sample">Update
            </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    </div>
  </div>
</template>

<script>
import AddCodeSample from '@/components/AddCodeSample'; // Import the AddCodeSample component
import UpdateCodeSample from '@/components/UpdateCodeSample'; // Import the AddCodeSample component
import axios  from 'axios';
export default {
  components: {
    AddCodeSample,
    UpdateCodeSample
    // Register the AddCodeSample component
  },
  data() {
    return {
      showFormAdd: false, // Flag to show/hide the form
      showFormUpdate:false,
      codeSamples: [], // Placeholder for existing code samples
      text: '',
      selectedItem: null,
      updateObject:null,
      items: ['Item 1', 'Item 2', 'Item 3']
    };
  },
  // Fetch existing code samples when the component is mounted
  async mounted() {
    await this.fetchCodeSamples();
  },
  methods: {
    async fetchCodeSamples() {
      try {
        const response = await fetch('/api/code-samples');
        if (!response.ok) {
          throw new Error('Failed to fetch code samples');
        }
        const data = await response.json();
        this.codeSamples = data;
      } catch (error) {
        console.error('Error fetching code samples:', error);
        // Handle error as needed
      }
    },
      async fetchCodeSamplesByLevel(level)  {
  try {
    const response = await axios.get(`/api/code-samples/${level}`);
    console.log(response.data)
    this.codeSamples = response.data;
    return response.data;
  } catch (error) {
    console.error(`Error fetching ${level} code samples:`, error);
    throw error;
  }
},
    async fetchCodeWithLevel(level) {
    try {
        const response = await fetch('/api/code-samples/'+level);
        if (!response.ok) {
          throw new Error('Failed to fetch code samples');
        }
        const data = await response.json();
        this.codeSamples = data;
      } catch (error) {
        console.error('Error fetching code samples:', error);
        // Handle error as needed
      }
    },
    async deleteCodeSample(id) {
      try {
        await axios.delete(`/api/code-samples/${id}`);
        this.fetchCodeSamples();
      } catch (error) {
        console.error('Error deleting code sample:', error);
      }
    },
    sortwithLevel(){
    const experienceLevels = ["Beginner", "Intermediate", "Advanced"];

// Sort the array of objects by experienceLevel
this.codeSamples.sort((a, b) => {
  const levelA = experienceLevels.indexOf(a.experienceLevel);
  const levelB = experienceLevels.indexOf(b.experienceLevel);
  // Sort based on the index of experienceLevel in the experienceLevels array
  return levelA - levelB;
});
    },
  }
};
</script>

<style>
/* Your component-specific styles */
</style>