import { createRouter, createWebHistory } from 'vue-router';
import UserList from '../components/UserList.vue';
import Samples from '../views/CodeSamples.vue'; // Import Samples component
import HomePage from '../views/HomePage.vue'; // Import Samples component
import LoginPage from '../views/LoginPage.vue'; // Import Samples component
import CreateAccount from '../views/CreateAccount.vue'; // Import Samples component
import ThreeJsScene from '../views/ThreeCanvas.vue'; // Import Samples component
const routes = [
  // Other routes...
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/createaccount',
    name: 'CreateAccount',
    component: CreateAccount
  },
  {
    path: '/3d',
    name: 'ThreeJsScene',
    component: ThreeJsScene
  },
  {
    path: '/users',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/samples',
    name: 'Samples',
    component: Samples
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;