<template>
    
      
     
    <div class="focusCard" @click="emitEvent(cardTopics.name)">
      
       <div style="display: flex;">
       <div style="display: inline-flex;width: max-content;border: 1px solid #ffffff47;border-radius: 8px;">
           
           <div style="margin: 0px; padding: 2px; padding-top: 46px; padding-left: 23px; height: 256px; width: 80%; max-width: 380px;height: 280px;">

            <div style="margin-top: 4px;"><span style="margin-left: 8px;font-size: 18px;margin-right: 8px;">
               <v-icon :icon="getIcon(cardTopics.name)" style="font-size: 46px;" :style="{ 'color': getIconColor(cardTopics.name) }"></v-icon>
                <span v-if="cardTopics.name == 'Algorithm'"> Data Structures & </span>{{cardTopics.name}} </span> 
            </div>
            <div style="height: 64px;margin-top: 8px;"> <p style="font-size: 14px;margin-left: 14px;margin-top: 8px;color: #a1a1aa;">{{cardTopics.text}}</p></div>
            <div style="display: inline-flex">
              <div style="margin: 4px;padding: 12px;">
                
                <div>Skill Mastery</div>

                
                
                <div style="display: inline-flex;">
                  <div><LevelRode v-if="activeSample.length != 0" :items="activeSample" :key="expCounter" ></LevelRode></div>        
              </div>
                
              </div>
                
              
          </div></div>
           <div style="align-items: center;vertical-align: middle;justify-content: center;display: flex;height: 100%;">
          
          <v-btn style="background-color: #0e1117;color: #ffffff47;" @click="emitEvent(cardTopics.name)" ><v-icon icon="mdi-arrow-right"></v-icon></v-btn></div>
       </div>
      </div>
    </div>
    
   
 </template>
 
 <script>
 import LevelRode from '@/components/LevelRode'; 
 import axios from 'axios';
 export default {

  components: {
    LevelRode,
    
  },
   data() {
     return {
      itemsMap: new Map(),
        iconsColor: new Map(),
        expiencesColor: new Map(),
        expCounter:0,
        samples:[],
     };
   },
   computed :{
    
   
    activeSample() { return this.samples}
  
  },
   props: {
    cardTopics: null,
  },
  
   mounted() {

    this.fetchCodeSamplesByTag(this.cardTopics.name)
    this.expCounter++;
   },
   methods: {
    emitEvent(content) {
      // Emitting the event named 'customEvent' with some data
      this.$emit('changeContent', content);
    },
    getIconColor(input) {
          return this.iconsColor.get(input);
    }, 
    getIcon(input) {
          return this.itemsMap.get(input);
    },
    getExpColor(input) {
          return this.expColor.get(input);
    },
    async fetchCodeSamplesByTag(tag)  {
  try {
    const tagValue = tag;
    const response = await axios.get(`/api/code-samples/tags/${tagValue}`);
    console.log('for level Rode',response.data)
    this.samples = response.data;
    this.expCounter++;
    return response.data;
  } catch (error) {
    console.error(`Error fetching ${tag} code samples:`, error);
    throw error;
  }
},
   },
   created() {
    // Original array
    const itemsArray = ['Frontend','Backend','Basic','Algorithm'];
    const experienceArray = ['Beginner','Intermediate','Advanced'];
    const experienceColor = ['#4CAF50','#ff9800','#F44336']
    // Corresponding icons
    const iconsArray = ['mdi-shield-crown-outline','mdi-cog-transfer-outline', 'mdi-book-education','mdi-code-block-braces',];
    const colorsArray = ['#f6df33','#47b884','#1d96e5','#56a249','#20ed69','#fede63']
    // Creating a map from the arrays
    this.itemsMap = new Map(itemsArray.map((item, index) => [item, iconsArray[index]]));
    this.iconsColor = new Map(itemsArray.map((item, index) => [item, colorsArray[index]]));
    this.expColor = new Map(experienceArray.map((item, index) => [item, experienceColor[index]]));
  },
 };
 </script>
 
 <style scoped>
  
  div {color: aliceblue;}

  .focusCard {
    background-color: #0e1117;
    width: 100%;
    max-width: 400px;
  }

 
  @media only screen and (max-width: 400px) {
    .focusCard {
    background-color: #0e1117;
    width: 100%;
    max-width: 360px;
    height: 280px;
  } 
}
  @media only screen and (min-width: 401px) and (max-width: 827px) {
    .focusCard {
    background-color: #0e1117;
    width: 100%;
    max-width: 400px;
    height: 280px;
  }   
}

 </style>