<template>
    <div class="center-container">
      <div class="container">
        <h1>Create Account</h1>
        <form @submit.prevent="createAccount" class="form">
          <div class="mb-3">
            <label for="username" class="form-label">Username</label>
            <input type="text" class="form-control" id="username" v-model="formData.username" required>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control" id="email" v-model="formData.email" required>
          </div>
          <div class="mb-3">
            <label for="address" class="form-label">Address</label>
            <input type="text" class="form-control" id="address" v-model="formData.address">
          </div>
          <div class="mb-3">
            <label for="salaryAmount" class="form-label">Salary Amount</label>
            <input type="number" class="form-control" id="salaryAmount" v-model="formData.salaryAmount">
          </div>
          <div class="mb-3">
            <label for="salaryCurrency" class="form-label">Salary Currency</label>
            <select class="form-select" id="salaryCurrency" v-model="formData.salaryCurrency">
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="JPY">JPY</option>
              <option value="CAD">CAD</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input type="password" class="form-control" id="password" v-model="formData.password" required>
          </div>
          <button type="submit" class="btn btn-primary">Create Account</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        formData: {
          username: '',
          email: '',
          address: '',
          salaryAmount: null,
          salaryCurrency: 'USD',
          password: ''
        }
      };
    },
    methods: {
      createAccount() {
        axios.post('/api/users', this.formData)
          .then(response => {
            console.log('Account created successfully:', response.data);
            // Optionally, you can redirect the user to a different page
          })
          .catch(error => {
            console.error('Error creating account:', error);
            // Handle error and show appropriate message to the user
          });
      }
    }
  }
  </script>
  
  <style scoped>
  .center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .container {
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .mb-3 {
    margin-bottom: 20px;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  </style>
  