<template>
    <div v-show="codeSamples!= null">   
        
            
          <div class="code-sample" :style="{ 'borderColor': getIconColor(codeSamples.languageType) }" >
            <div class="code-card" outlined color="grey darken-3">
  <div style="background-color: black;color: white;line-height: 24px;"> 
    <v-icon :icon="getIcon(codeSamples.languageType)" :style="{ 'color': getIconColor(codeSamples.languageType) }"></v-icon>
    
    <span style="color: aliceblue;text-align: center;vertical-align: bottom;">{{ codeSamples.title }}</span>  
    <span style="font-size: 8px;"> <v-icon :style="{ 'color': getIconColor(codeSamples.languageType) }"
     v-for="n in getLevel(codeSamples.experienceLevel)" :key="n">mdi-star</v-icon>  </span> </div>
     <div class="detail-card" style="font-size: 14px;"> {{ codeSamples.shortExp }}</div>
  <v-divider></v-divider>
  <highlightjs style="width: 100%;
    position: relative;"
        language="js"
        :code="codeSamples.code"
    />
    <v-divider></v-divider>
  <v-row>
    <v-col> 
    
    
  
  <div class="detail-card" v-for="(item, k) in codeSamples.detail" :key="k">
           
          <div v-if="item.detail?.code" style="display: block;position: relative;" > 
             <v-btn @click="copy2ClipBoard(item.detail.content)" class="custom-btn" style="background-color: #0d1117;color: white;position:absolute;float: right;right:8px"    icon="mdi-content-copy" size="x-small"></v-btn> 
             <highlightjs language="python" :code="item.detail.content"/></div>
          <div v-else style="color:white;"> <span v-if="item.detail?.cap" style="font-size: large;color: aqua;">{{ item.detail?.content }}</span>
          <span v-else> {{ item.detail?.content }}</span>
          
          </div>
        </div>

</v-col>
   
  </v-row>
  
  

</div>
  </div>
    
  
    
</div>
  </template>
  
  <script>
  import 'highlight.js/lib/common';
  import hljsVuePlugin from "@highlightjs/vue-plugin";
  
  export default {
    components: {
        highlightjs: hljsVuePlugin.component
    },
    data() {
      return {
        checkAuth: false,
        itemsMap: new Map(),
        iconsColor: new Map(),
      };
    
    },
    props: {
    codeSamples: null,
  },
  
    methods: {
//       highligtCode(input){
//         let highlightedCode = hljs.highlight(
//   input,
//   { language: 'javascript' }
// )
//      return highlightedCode;
//     },
    copy2ClipBoard(textToCopy){
      navigator.clipboard.writeText(textToCopy)
      .then(function() {
        // Provide feedback to the user
        alert('Text copied to clipboard');
      })
      .catch(function(err) {
        // Handle any errors
        console.error('Unable to copy text: ', err);
      });
    },
      getIcon(input) {
          return this.itemsMap.get(input);
    },
      getIconColor(input) {
          return this.iconsColor.get(input);
    }, 
      getLevel(input){
        if(input == 'Advanced') {
          return 5;
        }
        if(input == 'Intermediate') {
          return 3;
        }
        if(input == 'Beginner') {
          return 1;
        }
      },
    },
    created() {
    // Original array
    const itemsArray = ['javascript','react','vue','docker','nodejs','mongo','python'];

    // Corresponding icons
    const iconsArray = ['mdi-language-javascript','mdi-react','$vuetify', 'mdi-docker','mdi-nodejs', 'mdi-database','mdi-language-python'];
    const colorsArray = ['#f6df33','#74c3e0','#47b884','#1d96e5','#56a249','#20ed69','#fede63']

    // Creating a map from the arrays
    this.itemsMap = new Map(itemsArray.map((item, index) => [item, iconsArray[index]]));
    this.iconsColor = new Map(itemsArray.map((item, index) => [item, colorsArray[index]]));
  },
    mounted() {
      
    }
  };
  </script>
  
  <style scoped>
  .code-card {
    margin: 8px;
  }
  .code-sample {
    
    padding: 8px;
    border-radius: 4px;
    margin: 6px;border: 1px solid;
    max-width: 100%;
  }
  .detail-card,
  .code-parts-card {
    color: white;
  background-color: #06070c;
  padding: 8px;
  
  border-radius: 8px;
  margin-top: 8px;
  }
</style>
