<!-- AddCodeSample.vue -->
<template>
    <div style="max-height: 1200px;overflow: scroll;width: 100%;">
      <form @submit.prevent="saveCodeSample">
        <div>
          <label for="title">Title:</label>
          <input type="text" id="title" v-model="title" required>
        </div>
        <div>
          <label for="shortExp">Short Exp:</label>
          <input type="text" id="shortExp" v-model="shortExp" required>
        </div>
        <div>
          <label for="code">Code:</label>
          <textarea id="code" v-model="code" rows="5" required></textarea>
        </div>
        <div v-for="item,index in details" :key="index">
          <label for="detail">Detail:</label>
          <textarea id="detail" v-model="item.detail.content" rows="5" required></textarea>
          <v-checkbox style="color: black;" v-model="item.detail.code"   label="isCode"></v-checkbox>
          <v-checkbox  style="color: black;" v-model="item.detail.cap"   label="isCap"></v-checkbox>
        </div>
        <!-- <div v-for="item,index in codeParts" :key="index">
          <label for="detail">Code Parts:</label>
          <textarea id="detail" v-model="item.detail" rows="5" required></textarea>
        </div> -->
        <div> <v-btn prepend-icon="mdi-plus" @click="addDetail()">Add Details</v-btn></div>
        <!-- <div> <v-btn prepend-icon="mdi-plus" @click="addCodeParts()">Add CodeParts</v-btn></div> -->
       
        <div >
          <label for="languageType">Language Type:</label>
          <select id="languageType" v-model="languageType" required>
            <option value="">Select</option>
            <option value="javascript">Javascript</option>
            <option value="react">React</option>
            <option value="docker">Docker</option>
            <option value="nodejs">Nodejs</option>
            <option value="vue">VUE</option>
            <option value="python">Python</option>
            <option value="mongo">Mongo DB</option>
          </select>
        </div>
        <div >
          <label for="languageType">Language Type:</label>
          <select id="languageType" v-model="tags" multiple required>
            <option value="">Select</option>
            <option value="Backend">Backend</option>
            <option value="Frontend">Frontend</option>
            <option value="Algorithm">Algorithm</option>
            <option value="HTML">HTML</option>
            <option value="CSS">CSS</option>
            <option value="Basic">Basics</option>
          </select>
        </div>
        <div >
          <label for="experienceLevel">Experience Level:</label>
          <select id="experienceLevel" v-model="experienceLevel" required>
            <option value="">Select</option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
          </select>
        </div>
        <button type="submit">Save</button>
      </form>
      
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        title: '',
        shortExp:'',
        code: '',
        details:[{detail:{content:null, code:false,cap:false}}],
        tags:[],
        codeParts:[{detail:null}],
        languageType: '',
        experienceLevel: ''
      };
    },
    methods: {
      addDetail() {
        this.details.push({detail:{content:null, code:false}})
      },
      addCodeParts() {
        this.codeParts.push({detail:null})
      },
      async saveCodeSample() {
        try {
          const response = await fetch('/api/code-samples', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              title: this.title,
              shortExp:this.shortExp,
              code: this.code,
              codeParts: this.codeParts,
              detail: this.details,
              tags:this.tags,
              languageType: this.languageType,
              experienceLevel: this.experienceLevel
            })
          });
          if (!response.ok) {
            throw new Error('Failed to save code sample');
          }
          // Reset form fields after successful submission
          this.title = '';
          this.shortExp = '';
          this.code = '';
          this.detail = '';
          this.languageType = '';
          this.experienceLevel = '';
          alert('Code sample saved successfully');
        } catch (error) {
          console.error('Error saving code sample:', error);
          alert('Failed to save code sample. Please try again.');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Component-specific styles */
  form {
    margin-top: 20px;
  }
  form div {
    margin-bottom: 10px;
  }
  label {
    display: block;
    color: #000;
  }
  input[type="text"],
  textarea,
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  button:hover {
    background-color: #45a049;
  }
  </style>