<!-- App.vue -->
<template>
  <div id="app">
    
    <main>
      <!-- Router view for other pages -->
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    navigate(path) {
      this.$router.push(path);
    }
  }
}
</script>

<style>
/* Your global styles */

/* Header styles */

body{ 

  background-color: #000000;
  color: #fff;
  padding: 0px !important;
  margin: 0px !important;
  
}


::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



</style>
