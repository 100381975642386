<template>
  <div>
    <h2>User List</h2>
    <div v-if="users.length > 0" class="user-list">
      <div v-for="user in users" :key="user._id" class="user-card">
        <div class="user-info">
          <label>Amount:</label>
          <span>{{ user.salary.amount}} {{ user.salary.currency}}</span>
        </div>
        <div class="user-info">
          <label>Username:</label>
          <span>{{ user.username}}</span>
        </div>
        <div class="user-info">
          <label>Address:</label>
          <span>{{ user.address}}</span>
        </div>
        <div class="user-info">
          <label>Password:</label>
          <span>{{ user.password}}</span>
        </div>
      </div>
    </div>
    <p v-else>No users found.</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      users: []
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get('/api/users');
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }
  }
};
</script>

<style scoped>
.user-list {
  display: flex;
  flex-wrap: wrap;
}

.user-card {
  margin: 10px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: box-shadow 0.3s ease;
}

.user-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.user-info {
  margin-bottom: 10px;
}

.user-info label {
  font-weight: bold;
  margin-right: 5px;
}
</style>