<template>
    
      
     
     <div style="width: 200px;background-color:#0d1117;color:#FFF;border:1px solid #494c51;margin-left: 8px;padding:4px;border-left:4px solid #494c51;border-radius: 4px;">
      
    
      
      <div>
            <div  class="div4Slider"> <span> Beginner </span>
        
      </div>
      <v-slider class="sliderHeight" dense :thumb-size="1"
      v-model="slider1" 
      color="green" :step="1"
      
    >
    <template v-slot:append>
        <div style="font-size: 12px;">{{slider1}} </div> 
        </template>

</v-slider>
    </div>

    <div>
        <div class="div4Slider" > <span>Intermediate</span>
        
      </div>
    <v-slider class="sliderHeight" dense :thumb-size="1"
      v-model="slider2"
      color="orange" :step="1"
      
    ><template v-slot:append> <div style="font-size: 12px;">{{slider2}} </div>
         
        </template></v-slider>
    </div>
    
    <div>
        <div class="div4Slider"> <span> Advanced</span>
        
      </div>
    <v-slider class="sliderHeight" dense :thumb-size="1"
      v-model="slider3"
      color="red" :step="1"
      
    ><template v-slot:append>  <div style="font-size: 12px;">{{ slider3 }} </div>
          
        </template></v-slider>
    </div>
        
    
     </div>
     
    
  </template>
  
  <script>
  
  
  export default {
    data() {
      return {
        levels:null,
        slider1:0,
        slider2:0,
        slider3:0,
      };
    },
    props: {
    items: null,
  },
  created() {
    let _self = this;
    this.$nextTick(() => {
      _self.levels = this.countExperienceLevels(_self.items);
      console.log('levels', _self.levels);
      this.slider1 = _self.levels['Beginner'] ? _self.levels['Beginner'] : 0 ;
      this.slider2 = _self.levels['Intermediate'] ? _self.levels['Intermediate'] : 0;
      this.slider3 = _self.levels['Advanced']  ? _self.levels['Advanced'] : 0;
    });
    this.levels= _self.levels
    
  },
    mounted() {
     
    },
    methods: {
      countExperienceLevels(samples) {
    // Initialize an object to store the counts of different experience levels
    const levelCounts = {};

    // Loop through each sample
    samples.forEach(sample => {
        // Extract the experience level from the current sample
        const experienceLevel = sample.experienceLevel;

        // Check if the experience level already exists in the counts object
        if (levelCounts[experienceLevel]) {
            // If it exists, increment the count
            levelCounts[experienceLevel]++;
        } else {
            // If it doesn't exist, initialize the count to 1
            levelCounts[experienceLevel] = 1;
        }
    });

    // Return the object containing different experience levels and their counts
    this.expCounter = levelCounts;
    return levelCounts;
}
    }
  };
  </script>
  
  <style scoped>
   
   span { color :rgb(167, 167, 167);font-size: 12px;}
   .sliderHeight {
    height: 24px;
   }

   .div4Slider{
    height: 12px;
   }
  </style>