<template>
  <div>  
    <header class="header">
      <div class="logo">
        <!-- Add your logo or brand name here -->
        <v-icon icon="mdi-code-braces"></v-icon> funcodemy
      </div>
      <!-- Left panel menu -->
      <div class="panel" :class="{ open: menuOpen }">
      <!-- Toggle button inside the panel -->
      <div class="toggle-btn" @click="toggleMenu">
        <button> <v-icon :icon=" menuOpen ? 'mdi-code-less-than' : 'mdi-code-greater-than' "></v-icon> </button>
      </div>

      <!-- Your menu content goes here -->
      <div class="menu-content" >
        <ul>
          <li><v-btn class="custom-btn" @click="fetchCodeSamplesByType('javascript')" prepend-icon="mdi-language-javascript" style="text-transform: capitalize;">Javascript</v-btn></li>
          <v-divider></v-divider>
          <li><v-btn class="custom-btn" @click="fetchCodeSamplesByType('react')" prepend-icon="mdi-react" >react</v-btn></li>
          <v-divider></v-divider>
          <v-divider></v-divider>
          <li><v-btn class="custom-btn" @click="fetchCodeSamplesByType('vue')" prepend-icon="$vuetify" >Vue</v-btn></li>
          <v-divider></v-divider>
          <li><v-btn class="custom-btn" @click="navigate('/3d')" prepend-icon="mdi-cube">3D Scene </v-btn></li>
          <v-divider></v-divider>
          <li><v-btn class="custom-btn" @click="fetchCodeSamplesByType('docker')" prepend-icon="mdi-docker">Docker </v-btn></li>
          <v-divider></v-divider>
          <li><v-btn class="custom-btn" @click="fetchCodeSamplesByType('nodejs')" prepend-icon="mdi-nodejs">Node.js </v-btn></li>
          <v-divider></v-divider>
          <li><v-btn class="custom-btn" @click="fetchCodeSamplesByType('mongo')" prepend-icon="mdi-database">Mongo DB </v-btn></li>
          <v-divider></v-divider>
          <li><v-btn class="custom-btn" @click="fetchCodeSamplesByType('python')"  prepend-icon="mdi-language-python" >Python</v-btn></li>
          

          <li><v-btn class="custom-btn" @click="gotoFocusAreas()"  prepend-icon="mdi-package" >Core Objectives</v-btn></li>
          
          <!-- <v-divider></v-divider>
          <li><v-btn class="custom-btn" @click="fetchCodeSamplesByType('python')"  prepend-icon="mdi-tree" >Data Structures & Algorithms</v-btn></li> -->
        </ul>
      </div>
    </div>

    <!-- Toggle button outside the menu -->
      <nav class="nav">
        <v-btn v-if="false" @click="navigate('/users')">Users</v-btn>
      </nav>
      <v-btn  v-if="false" class="logout-btn" @click="logout">Logout</v-btn>
    </header>
    
    <div  class="main-content" :style="{ '--panel-width': menuOpen ? '15vw' : '0px','left':menuOpen ?'15vw' : '0px'  }"> 

      <div v-if="cardDataContent != null" style="width: 100%;height: 100vh;overflow-y: scroll;position: absolute;"><div style="position: relative;" ><CodeCard :codeSamples="cardDataContent"></CodeCard></div></div>
      
       
      <div v-if="selectedTag == null" style="position: relative;display: flex;align-items: center;justify-content: center;vertical-align:middle;"> 

        <!-- Focus Card -->
        <div  style="position: relative;display: flex;height: 100%;
      align-items: center;
      justify-content: center;
      vertical-align: middle;">
        <div class="card-scatter" >
        <FocusCard  v-for="topic,c in focusTopics"   :key="c" :cardTopics="topic"  @changeContent="fetchCodeSamplesByTag($event),selectedTag = $event" style="margin: 8px;" ></FocusCard>
      </div>
      
      
        
      
      </div>
      </div>
      
     <div style="width: 100%;">
      <div 
      style="
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      vertical-align: middle;"
      >
          <div  v-if="cardDataContent == null && selectedTag != null" style="display: block;width: 100%;">
          <div style="display:flex;justify-content: center ;margin-top: 12px;position: relative;">
            <v-btn class="flash-back" density="compact" icon="mdi-arrow-left-circle" style="position: absolute;left:4px;margin-top: 6px;"></v-btn> 
            <div style="width: 300px;">
              <v-text-field hide-details="auto"  v-model="searchInput" @update:model-value="searchInSamplesByTitle(searchInput)" variant="outlined" append-inner-icon="mdi-magnify" density="compact" label="Search" ></v-text-field>
            </div>
          </div>
          <div v-if="false" style="display:flex;justify-content: center ;margin-top: 6px;">
            <div style="display: inline-flex;">
              <v-btn size="x-small" icon="mdi-led-variant-on" :style="{ 'color': 'green' }" style="background-color: #040506;border:1px solid gray"></v-btn>
              <v-btn size="x-small" icon="mdi-led-variant-on" :style="{ 'color': 'orange' }" style="background-color: #040506;border:1px solid gray"></v-btn>
              <v-btn size="x-small" icon="mdi-led-variant-on" :style="{ 'color': 'red' }" style="background-color: #040506;border:1px solid gray"></v-btn>
              
            </div>
              
            </div>
          <div style="width: 100%;position: relative;margin-top: 8px;"><TopicCard style="margin:4px;" v-for="topic,c in usedSamples"   :key="c" :cardTopics="topic"  @changeContent="cardDataContent=$event" ></TopicCard></div>
          
          
        </div>
        
        </div>
     </div>
      
    
    
    
    </div>
    <div v-if="samples.length != 0 && false "><TopicCounter :items="samples" :key="expCounter"></TopicCounter>
           
            
          </div> 
  </div>
</template>

<script>
import CodeCard from '@/components/CodeCard'; // Import the AddCodeSample component
import TopicCounter from '@/components/TopicCounter'; 
import TopicCard from '@/components/TopicCard';
import FocusCard from '@/components/FocusCard'; 
import axios from 'axios';


export default {
  components: {
    CodeCard,
    TopicCounter, // Register the AddCodeSample component
    TopicCard,
    FocusCard,
    
  },
  data() {
    return {
      checkAuth: false,
      menuOpen:true,
      showForm: false, // Flag to show/hide the form
      samples: [], // Placeholder for existing code samples
      filterInSamples:[],
      usedSamples:[],
      text: '',
      selectedItem: null,
      cardDataContent:null,
      items: ['Item 1', 'Item 2', 'Item 3'],
      selectedTag:null,
      expCounter: 0,
      searchInput:null,
      focusTopics: [
        { name:'Backend', text:' Server-side of a web application, where data processing, storage, and management occur'},
        { name:'Frontend',text:' Part of a website or application that users interact with directly'},
        { name:'Basic', text:'Control Flow,Debugging and Troubleshooting, Functions, OOP, Version Control'},
        { name:'Algorithm', text:'Step-by-step procedures to solve problems, organize and store data efficiently',}
      ]
        
    };
  },
  computed :{
    
   
    activeSample() { 
      
      
      
      return this.samples}
  
  },
  methods: {
    



    searchInSamplesByTitle(keyword) {
      this.filterInSamples = [...this.samples];
      
this.usedSamples =  this.samples.filter(item => item.title.toLowerCase().includes(keyword.toLowerCase()));
},
    toggleMenu(){this.menuOpen = !this.menuOpen},
    navigate(path) {
      this.$router.push(path);
    },
    async logout() {
      localStorage.removeItem('userAuth');
      try {
        const response = await axios.get('/api/logout');
        if (response.status === 200) {
          // Redirect to login page or perform other actions after logout
          this.$router.push('/login');
        } else {
          console.error('Logout error:', response.data);
        }
      } catch (error) {
        console.error('Logout error:', error);
      }
    },
    async fetchCodeSamples() {
      try {
        const response = await fetch('/api/code-samples');
        if (!response.ok) {
          throw new Error('Failed to fetch code samples');
        }
        const data = await response.json();
        this.samples = data;
        this.filterInSamples = data;
        this.usedSamples = data;
        this.expCounter++;
      } catch (error) {
        console.error('Error fetching code samples:', error);
        // Handle error as needed
      }
    },
      async fetchCodeSamplesByLevel(level)  {
  try {
    const response = await axios.get(`/api/code-samples/experience/${level}`);
    console.log(response.data)
    this.samples = response.data;
    
    return response.data;
  } catch (error) {
    console.error(`Error fetching ${level} code samples:`, error);
    throw error;
  }
},

gotoFocusAreas()  {
  this.selectedTag = null;
  this.samples = [] },

async fetchCodeSamplesByTag(tag)  {
  this.selectedTag = null;
  this.samples = []
  try {
    const tagValue = tag;
    const response = await axios.get(`/api/code-samples/tags/${tagValue}`);
    console.log(response.data)
    this.samples = response.data;
    this.usedSamples = response.data;
    this.cardDataContent=null;
    this.expCounter++;
    return response.data;
  } catch (error) {
    this.cardDataContent=null;
    console.error(`Error fetching ${tag} code samples:`, error);
    throw error;
  }
},

async fetchCodeSamplesByType(type)  {
  this.selectedTag = type;
  try {
    const response = await axios.get(`/api/code-samples/language/${type}`);
    console.log(response.data)
    this.samples = response.data;
    this.usedSamples = response.data;
    this.cardDataContent=null;
    this.expCounter++;
    return response.data;
  } catch (error) {
    this.cardDataContent=null;
    console.error(`Error fetching ${type} code samples:`, error);
    throw error;
  }
},
    async fetchCodeWithLevel(level) {
    try {
        const response = await fetch('/api/code-samples/'+level);
        if (!response.ok) {
          throw new Error('Failed to fetch code samples');
        }
        const data = await response.json();
        this.samples = data;
        this.expCounter++;
      } catch (error) {
        console.error('Error fetching code samples:', error);
        // Handle error as needed
      }
    },
    
  },
  mounted() {
    // Check if user is authenticated on component mount
    // this.checkAuth = localStorage.getItem('userAuth');
    // if (!this.checkAuth) {
    //   this.$router.push('/login');
    // }
    //this.fetchCodeSamples();
  }
};
</script>

<style scoped>
/* Your component-specific styles */
body {
        margin: 0; /* Remove default margin */
        padding: 0; /* Remove default padding */
        height: 100vh; /* Make body full height */
        position: relative; /* Positioning context for absolute positioning */
    }
.container {
  display: flex;
        justify-content: space-between; /* Distribute items evenly */
        position: absolute;
        bottom: 8px; /* Position at the bottom */
        width: 100%; /* Take full width */
    }

    .box {
        width: calc(30.33% - 10px); /* Each box occupies 33% of viewport width */
        height: 200px; /* Just for demonstration */
        border: 4px solid #8e98f0;
        border-radius: 12px;
        background-color: #6874E8;
        box-sizing: border-box; /* Include border and padding in the width */
        margin-right: 12px; /* Margin between divs */
        margin-top: 8px;
        margin-left: 8px;
        color: white;
        font-size: 24px;
        text-align: end;
    }

    /* Remove right margin from the last div */
    .box:last-child {
        margin-right: 8px;
    }
.header {
  background-color: #0f1117;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
}
.custom-btn {
  background-color: #0f1117;
  color: #fff;
  width: 99%;
  text-align: left;
  justify-content: left;
  align-items: center;
  text-transform: capitalize;
  position: relative;
  text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
  overflow: hidden; /* Hide overflowing content */
  white-space: nowrap;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav {
  display: flex;
}

.nav v-btn {
  margin: 0 10px;
}

.nav v-btn:first-child {
  margin-left: 0;
}

.nav v-btn:last-child {
  margin-right: 0;
}

.nav v-btn:hover {
  background-color: #555;
}

.logout-btn {
  float: right;
}
.code-sample {
  background-color: #ffffff;
}
.panel {
      width: 15vw;
      height: 100vh;
      background-color: #0f1117;
      position: fixed;
      top: 5vh;
      left: -15vw;
      transition: left 0.3s ease;
      z-index: 1000;
      min-width: 60px;
    }

    .panel.open {
      left: 0;
    }

    .toggle-btn {
      cursor: pointer;
      position: absolute;
      top: 0px;
      background-color: #1D2B53;
      left: 15vw;
      border-radius: 3px;
    }

    .main-content {
      background-color: #040506;
      /* Initial width minus the panel width */
      height: 95vh;

       /* Add transition effect */
      overflow:auto; /* Add overflow:auto to main content */
      position: relative;
      max-width:100vw;
      width: calc(100vw - var(--panel-width, 0px)); 
    }
    .menu-content {
      max-width: 15vw;
    }

    .card-scatter {
      margin-top: 36px;
      width: 100%;display: grid;
      width: max-content;
      grid-template-columns: 1fr 1fr;
    }

    .flash-back{display: none;}

    @media only screen and (max-width: 975px) {
      .menu-content {
      display: none;
    }
    .panel { display: none;width: 0px;
    }
    .main-content { width: 100vw;left: 0px !important;display: flex;overflow: scroll;padding-left: 4px;align-items: baseline;padding-right: 5px;padding-top: 8px;}
    .card-scatter {display: block !important;margin-top: 0px;}
    .flash-back{display: block;}
}
</style>
