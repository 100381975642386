<template>
    
      
     
    <div style="background-color: #0e1117;" @click="emitEvent(cardTopics)">
      
       <div style="display: flex;">
       <div style="display: inline-flex;width: 100%;border: 1px solid #ffffff47;border-radius: 8px;">
           <div style="vertical-align: middle;display: flex;align-items: center;width: 80px;justify-content: center;"><v-icon :icon="getIcon(cardTopics.languageType)" :style="{ 'color': getIconColor(cardTopics.languageType) }"></v-icon></div>
           <div style="margin: 0px;padding: 2px;width: 100%;"> 
            <div style="margin-top: 4px;"><span style="margin-left: 8px;font-size: 16px;font-family: Roboto;">{{cardTopics.title}} </span></div>
            <div> <span style="font-size: 14px;margin: 8px;color: #a1a1aa;">{{cardTopics.shortExp}}</span></div>
            <div style="display: inline-flex">
              <div style="margin: 8px;" > <span > <v-icon icon="mdi-led-variant-on" style="rotate: 30deg;" :style="{ 'color': getExpColor(cardTopics.experienceLevel) }"></v-icon> <span  style="color: #a1a1aa;font-size: 12px;">{{ cardTopics.experienceLevel }}</span>  </span></div>
              <div style="margin-top: 6px;"><span style="font-size: 8px;"><v-icon icon="mdi-tag" ></v-icon></span></div> 
              <div style="margin-left: 4px;margin-top: 8px;color: #a1a1aa;"><span  v-for="(tag,index) in cardTopics.tags" :key="index" style="margin-right: 0px;font-size: 12px;font-family: Roboto;">{{tag}}  <span v-if="index != cardTopics.tags.length-1">, </span></span> </div>
          </div></div>
           <div style="align-items: center;vertical-align: middle;justify-content: center;display: flex;height: 100%;">
          
          <v-btn style="background-color: #0e1117;color: #ffffff47;" @click="emitEvent(cardTopics)" ><v-icon icon="mdi-arrow-right"></v-icon></v-btn></div>
       </div>
      </div>
    </div>
    
   
 </template>
 
 <script>
 
 
 export default {
   data() {
     return {
      itemsMap: new Map(),
        iconsColor: new Map(),
        expiencesColor: new Map(),
     };
   },
   props: {
    cardTopics: null,
  },
   mounted() {
     
   },
   methods: {
    emitEvent(content) {
      // Emitting the event named 'customEvent' with some data
      this.$emit('changeContent', content);
    },
    getIconColor(input) {
          return this.iconsColor.get(input);
    }, 
    getIcon(input) {
          return this.itemsMap.get(input);
    },
    getExpColor(input) {
          return this.expColor.get(input);
    },
   },
   created() {
    // Original array
    const itemsArray = ['javascript','react','vue','docker','nodejs','mongo','python'];
    const experienceArray = ['Beginner','Intermediate','Advanced'];
    const experienceColor = ['#4CAF50','#ff9800','#F44336']
    // Corresponding icons
    const iconsArray = ['mdi-language-javascript','mdi-react','$vuetify', 'mdi-docker','mdi-nodejs', 'mdi-database','mdi-language-python'];
    const colorsArray = ['#f6df33','#74c3e0','#47b884','#1d96e5','#56a249','#20ed69','#fede63']
    // Creating a map from the arrays
    this.itemsMap = new Map(itemsArray.map((item, index) => [item, iconsArray[index]]));
    this.iconsColor = new Map(itemsArray.map((item, index) => [item, colorsArray[index]]));
    this.expColor = new Map(experienceArray.map((item, index) => [item, experienceColor[index]]));
  },
 };
 </script>
 
 <style scoped>
  
  div {color: aliceblue;}
 
 </style>