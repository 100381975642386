<template>
  <div v-show="!isLoaded" class="loading-spinner"></div>
    <div v-show="isLoaded" ref="container" class="container"></div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default {
  name: 'ThreeJsScene',
  data() {
    return {
      isLoaded: false,
      moveSpeed: 0.05,
      rotationSpeed: 0.02,
      velocity: new THREE.Vector3(0, 0, 0),
      acceleration: 0.001,
      deceleration: 0.002,
      maxVelocity: 0.2,
      movingForward: false,
      movingBackward: false,
      braking: false,
      rotatingLeft: false,
      rotatingRight: false,
    };
  },
  mounted() {
    
    this.init();
    this.addEventListeners();
    window.addEventListener('resize', this.onWindowResize);
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.scene = new THREE.Scene();

        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.camera.position.set(0, 5, 10);

        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(window.innerWidth,  window.innerHeight);
        this.$refs.container.appendChild(this.renderer.domElement);

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.25;
        this.controls.screenSpacePanning = false;
        this.controls.maxPolarAngle = Math.PI / 2;

         // Create the plane
  const textureLoader = new THREE.TextureLoader();
  const roadTexture = textureLoader.load('../road.jpg');
  roadTexture.wrapS = THREE.RepeatWrapping;
  roadTexture.wrapT = THREE.RepeatWrapping;
  roadTexture.repeat.set(1, 1); // Repeat the texture to cover the plane
  const roadMaterial = new THREE.MeshBasicMaterial({ map: roadTexture });
  const roadGeometry = new THREE.PlaneGeometry(50, 50); // Increase the size of the plane
  const road = new THREE.Mesh(roadGeometry, roadMaterial);
  this.scene.add(road);

  // Position the plane so that it is visible from all angles
  road.rotation.x = -Math.PI / 2;
  road.position.y = -1

        const geometry = new THREE.BoxGeometry();
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        this.cube = new THREE.Mesh(geometry, material);
        this.scene.add(this.cube);

        const arrowDirection = new THREE.Vector3(0, 0, -1);
const arrowLength = 2; // Increase the length
const arrowHeadSize = 0.5; // Increase the size of the arrow head
const arrowColor = 0xff0000; // Red color

const arrowHelper = new THREE.ArrowHelper(
  arrowDirection,
  this.cube.position,
  arrowLength,
  arrowColor,
  undefined,
  undefined,
  arrowHeadSize
);
        
        
this.cube.add(arrowHelper);
        this.renderer.domElement.addEventListener('click', this.onMouseClick);
        this.isLoaded = true;
        this.animate();
      });
    },
    onWindowResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.renderer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    },
    addEventListeners() {
      document.addEventListener('keydown', this.onKeyDown);
      document.addEventListener('keyup', this.onKeyUp);
    },
    removeEventListeners() {
      document.removeEventListener('keydown', this.onKeyDown);
      document.removeEventListener('keyup', this.onKeyUp);
    },
    onKeyDown(event) {
      switch (event.keyCode) {
        case 32: // Spacebar (Brake)
          this.braking = true;
          break;
        case 37: // Left arrow key
          this.rotatingLeft = true;
          break;
        case 39: // Right arrow key
          this.rotatingRight = true;
          break;
        case 38: // Up arrow key
          this.movingForward = true;
          break;
        case 40: // Down arrow key
          this.movingBackward = true;
          break;
      }
    },
    onKeyUp(event) {
      switch (event.keyCode) {
        case 32: // Spacebar (Brake)
          this.braking = false;
          break;
        case 37: // Left arrow key
          this.rotatingLeft = false;
          break;
        case 39: // Right arrow key
          this.rotatingRight = false;
          break;
        case 38: // Up arrow key
          this.movingForward = false;
          break;
        case 40: // Down arrow key
          this.movingBackward = false;
          break;
      }
    },
    animate() {
      
  try {
    const animateFrame = () => {
      try {
        requestAnimationFrame(animateFrame);
      } catch (error) {
        console.error("Error in requestAnimationFrame:", error);
      }

      try {
        this.controls.update();
      } catch (error) {
        console.error("Error in updating controls:", error);
      }

      // Handling rotation
      try {
        if (this.rotatingLeft) {
          this.cube.rotation.y += this.rotationSpeed;
        }
        if (this.rotatingRight) {
          this.cube.rotation.y -= this.rotationSpeed;
        }
      } catch (error) {
        console.error("Error in handling rotation:", error);
      }

      // Handling translation
      try {
        if (this.movingForward) {
          this.accelerate();
        }
        if (this.movingBackward) {
          this.decelerate();
        }
        if (this.braking) {
          this.brake();
        }
      } catch (error) {
        console.error("Error in handling translation:", error);
      }

      // Limit velocity
      try {
        this.velocity.clampLength(-this.maxVelocity, this.maxVelocity);
      } catch (error) {
        console.error("Error in limiting velocity:", error);
      }

      // Move the car only if not fully braked
      try {
        if (!this.braking || Math.abs(this.velocity.y) > 0.001) {
          const direction = new THREE.Vector3(0, 0, -1).applyQuaternion(this.cube.quaternion);
          direction.multiplyScalar(this.velocity.y);
          this.cube.position.add(direction);
        }
      } catch (error) {
        console.error("Error in moving the car:", error);
      }

      try {
        this.renderer.render(this.scene, this.camera);
      } catch (error) {
        console.error("Error in rendering the scene:", error);
      }
    };
    animateFrame();
  } catch (error) {
    console.error("Error in animate function:", error);
  }
},
    accelerate() {
      if (this.velocity.y < this.maxVelocity) {
        this.velocity.y += this.acceleration;
      }
    },
    decelerate() {
      if (this.velocity.y > -this.maxVelocity) {
        this.velocity.y -= this.acceleration;
      }
    },
    brake() {
      if (Math.abs(this.velocity.y) > this.deceleration) {
        this.velocity.y -= Math.sign(this.velocity.y) * this.deceleration;
      } else {
        this.velocity.y = 0;
      }
    },
    onMouseClick(event) {
  // Get the mouse position relative to the container element
  const rect = this.renderer.domElement.getBoundingClientRect();
  const mouse = {
    x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
    y: -((event.clientY - rect.top) / rect.height) * 2 + 1
  };

  // Create a raycaster from the camera to the mouse position
  const raycaster = new THREE.Raycaster();
  raycaster.setFromCamera(mouse, this.camera);

  // Find intersected objects
  const intersects = raycaster.intersectObjects(this.scene.children, true);

  // If an intersection is found, add a point at the intersection position
  if (intersects.length > 0) {
    // Get intersection point in world coordinates
    const point = intersects[0].point.clone();

    // Add point to scene
    const pointGeometry = new THREE.BufferGeometry().setFromPoints([point]);
    const pointMaterial = new THREE.PointsMaterial({ color: 0xff0000 });
    const pointObject = new THREE.Points(pointGeometry, pointMaterial);
    this.scene.add(pointObject);
  }
}
  },
  beforeUnmount() {
    this.renderer.domElement.removeEventListener('click', this.onMouseClick);
    this.removeEventListeners();
    this.controls.dispose();
    this.$refs.container.removeChild(this.renderer.domElement);
    this.renderer.dispose();
    this.scene.remove(this.cube);
    this.cube.geometry.dispose();
    this.cube.material.dispose();
    this.camera = null;
    this.renderer = null;
    this.scene = null;
    this.cube = null;
   
  },
};
</script>

<style scoped>
.container {
  width: 100vw; /* Set width to 100% of viewport width */
  height: 100vh;
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0;
}

.loading-spinner::after {
  content: '';
  width: 50px;
  height: 50px;
  border: 3px solid #ccc;
  border-radius: 50%;
  border-top-color: #333;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>