<!-- AddCodeSample.vue -->
<template>
    <div style="max-height: 1200px;overflow: scroll;width: 100%;">
      <form @submit.prevent="updateCodeSample">
        <div>
          <label for="title">Title Update:</label>
          <input type="text" id="title" v-model="codeSample.title" required>
        </div>
        <div>
          <label for="code">Code:</label>
          <textarea id="code" v-model="codeSample.code" rows="5" required></textarea>
        </div>
        <div v-for="item,index in codeSample.detail" :key="index">
          <label for="detail">Detail:</label>
          <textarea id="detail" v-model="item.detail.content" rows="5" required></textarea>
          <v-checkbox v-model="item.detail.code"   label="isCode"></v-checkbox>
        </div>
        <!-- <div v-for="item,index in codeParts" :key="index">
          <label for="detail">Code Parts:</label>
          <textarea id="detail" v-model="item.detail" rows="5" required></textarea>
        </div> -->
        <div> <v-btn prepend-icon="mdi-plus" @click="addDetail()">Add Details</v-btn></div>
        <!-- <div> <v-btn prepend-icon="mdi-plus" @click="addCodeParts()">Add CodeParts</v-btn></div> -->
       
        <div >
          <label for="languageType">Language Type:</label>
          <select id="languageType" v-model="codeSample.languageType" required>
            <option value="">Select</option>
            <option value="javascript">Javascript</option>
            <option value="docker">Docker</option>
            <option value="nodejs">Nodejs</option>
            <option value="vue">VUE</option>
            <option value="python">Python</option>
            <option value="mongo">Mongo DB</option>
          </select>
        </div>
        <div >
          <label for="experienceLevel">Experience Level:</label>
          <select id="experienceLevel" v-model="codeSample.experienceLevel" required>
            <option value="">Select</option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
          </select>
        </div>
        <button type="submit">Update</button>
      </form>
      
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        
        codeSample: {
            title: this.updateObject.title,
        code: this.updateObject.code,
        detail:this.updateObject.detail,
        codeParts:[{detail:null}],
        languageType: this.updateObject.languageType,
        experienceLevel: this.updateObject.experienceLevel
        }
      };
    },
    props: {
    updateObject: null,
  },
    methods: {
      addDetail() {
        this.codeSample.detail.push({detail:{content:null, code:false}})
      },
      addCodeParts() {
        this.codeParts.push({detail:null})
      },
      async updateCodeSample() {
        
        let sample =   JSON.parse(JSON.stringify(this.codeSample))
        console.log(sample)
      try {
        const response = await axios.put(`/api/code-samples/${this.updateObject._id}`, sample);
        console.log(response.data); // log the updated code sample
        // Optionally, you can redirect the user or show a success message
      } catch (error) {
        console.error(error);
        // Handle error, show error message, etc.
      }
    },
    }
  };
  </script>
  
  <style scoped>
  /* Component-specific styles */
  form {
    margin-top: 20px;
  }
  form div {
    margin-bottom: 10px;
  }
  label {
    display: block;
  }
  input[type="text"],
  textarea,
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  button:hover {
    background-color: #45a049;
  }
  </style>