<template>
    
      
     
    <div style="display: inline-flex;width: 192px;height:8px;border-radius: 6px;border: 1px solid coral;" >
       
       <div :style="{width: (slider1/sum)*192 +'px' }" style="background-color: greenyellow;"></div>
       <div :style="{width: (slider2/sum)*192 +'px' }" style="background-color: orange;"></div>
       <div :style="{width: (slider3/sum)*192 + 'px' }" style="background-color: orangered;"></div>
    </div>
    
   
 </template>
 
 <script>
 
 
 export default {
   data() {
     return {
       levels:null,
       slider1:0,
       slider2:0,
       slider3:0,
       sum:0
     };
   },
   props: {
   items: null,
 },
 created() {
  let _self = this;
     this.$nextTick(() => {
      if(_self.items.length !=0 ) {
        _self.levels = this.countExperienceLevels(_self.items);
      console.log('levels', _self.levels);
      this.slider1 = _self.levels['Beginner'] ? _self.levels['Beginner'] : 0 ;
       this.slider2 = _self.levels['Intermediate'] ? _self.levels['Intermediate'] : 0;
      this.slider3 = _self.levels['Advanced']  ? _self.levels['Advanced'] : 0;
       this.sum = this.slider1+ this.slider2+this.slider3;
      }
     
     });
    this.levels= _self.levels
   
 },
   mounted() {
    
   },
   methods: {
     countExperienceLevels(samples) {
   // Initialize an object to store the counts of different experience levels
   const levelCounts = {};

   // Loop through each sample
   samples.forEach(sample => {
       // Extract the experience level from the current sample
       const experienceLevel = sample.experienceLevel;

       // Check if the experience level already exists in the counts object
       if (levelCounts[experienceLevel]) {
           // If it exists, increment the count
           levelCounts[experienceLevel]++;
       } else {
           // If it doesn't exist, initialize the count to 1
           levelCounts[experienceLevel] = 1;
       }
   });

   // Return the object containing different experience levels and their counts
   this.expCounter = levelCounts;
   return levelCounts;
}
   }
 };
 </script>
 
 <style scoped>
  
  span { color :rgb(167, 167, 167);font-size: 12px;}
  .sliderHeight {
   height: 24px;
  }

  .div4Slider{
   height: 12px;
  }
 </style>