<template>
  <div class="login-container">
    <div class="login-card">
      <h1>User Login</h1>
      <form @submit.prevent="login">
        <div>
          <label for="username">Username:</label>
          <input type="text" id="username" v-model="username" required>
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        <button type="submit">Login</button>
      </form>
      <p v-if="error">{{ error }}</p>
      <!-- <button @click="logout">Logout</button> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      error: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('/api/login', {
          username: this.username,
          password: this.password
        });

        if (response.status === 200) {
          // Redirect to profile page or perform other actions on successful login
          localStorage.setItem("userAuth", true);
          this.$router.push('/');
        } else {
          this.error = 'Incorrect username or password';
        }
      } catch (error) {
        console.error('Login error:', error);
        this.error = 'An error occurred while logging in';
      }
    },
    async logout() {
      try {
        const response = await axios.get('/api/logout');
        if (response.status === 200) {
          // Redirect to login page or perform other actions after logout
          this.$router.push('/login');
        } else {
          // Handle logout error
          console.error('Logout error:', response.data);
        }
      } catch (error) {
        console.error('Logout error:', error);
      }
    }
  }
};
</script>

<style>

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #222; /* Darker color */
}

.login-card {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-card h1 {
  margin-top: 0;
  text-align: center;
}

.login-card form {
  display: flex;
  flex-direction: column;
}

.login-card form div {
  margin-bottom: 10px;
}

.login-card form label {
  font-weight: bold;
}

.login-card form input[type="text"],
.login-card form input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-card form button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.login-card form button:hover {
  background-color: #0056b3;
}

.login-card p {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.login-card button {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #dc3545;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.login-card button:hover {
  background-color: #bd2130;
}
</style>
